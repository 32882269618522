import Image from 'next/image'

import { Container, If, Tag, Title } from '@/components'

import Man from '/public/images/faq-man.webp'

import { Faq, Filters } from './components'

import { useHelp } from './useHelp'

import styles from './Help.module.scss'

export const Help = ({ shouldRenderFilters }) => {
  const { faqItems, activeFilterIndex, setActiveFilterIndex } = useHelp({
    shouldRenderFilters
  })

  return (
    <section id="ajuda" className={styles.help}>
      <Container>
        <Tag text="Ajuda" />

        <Title
          customClass={styles.help__title}
          text={
            <>
              Ficou com alguma dúvida? <br /> A Equifax | BoaVista responde
            </>
          }
        />

        <div
          data-animate="fade-up"
          className={`${styles.help__wrapper} animate-init`}
        >
          <If
            condition={shouldRenderFilters}
            renderIf={
              <Filters
                activeFilterIndex={activeFilterIndex}
                setActiveFilterIndex={setActiveFilterIndex}
              />
            }
            renderElse={
              <div className={styles.help__image}>
                <Image
                  src={Man}
                  alt="homem com tablet"
                  style={{
                    width: '100%',
                    height: 'auto'
                  }}
                />
              </div>
            }
          />

          <Faq items={faqItems} />
        </div>
      </Container>
    </section>
  )
}
